
/* 
body img {
  filter: blur(10px);
} */

.App {
  text-align: center;
  padding-left: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-text-right{
	text-align: right;
}

.full-img {
  max-width: 100%;
  height: auto;
}

.img-control{
  position: absolute;
  top:50%;
  width: 100%;
  display: none;
}

.show-img {
  position: relative;
}

.show-img:hover img {
  opacity: 50%;
}

.show-img:hover .img-control {
  display: block;
}

.mainContainer {
  padding: 15px;
  background-color: 'background.paper';
  opacity: '90%';
  width: '80%';
  margin: 'auto'
}

.cardHeader {
  display: block;
  overflow: hidden;
}

.cardHeaderRoot {
  overflow: hidden;
}

.cardHeadertitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cardHeaderSubTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hiddenControl {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
}

.hoverContainer {
  position: relative;
}

.hoverContainer:hover .hiddenControl {
  visibility: visible;
}

.hoverClickHint:hover {
  opacity: 70%;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.clickable:active {
  transform: scale(0.95);
  opacity: 70%;
}

.cartouche {
  min-height: 6vh;
  display: flex;
  align-items: center; 
  align-content: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}